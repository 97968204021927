import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flex-grid-system";
import theme from "@/styles/theme";
import CtaLink from "src/components/ctaLink";
import { useTranslation } from "next-i18next";
import { StyledCard } from "@/styles/style";
import ReactMarkdown from "react-markdown";
import GuideArticleImg from "@/components/guideArticle/guideArticleImg";

const CtaWrapper = styled.div`
  padding: 0 1rem;
`;

const ImgWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const ProsList = styled.div`
  flex: 1;
  ul {
    text-align: left;
    list-style-type: none;
    color: ${theme.grey_1};
    font-size: 1rem;
    li {
      position: relative;
      &:before {
        display: inline-flex;
        text-indent: -1rem;
        content: "✅";
        font-size: 0.7rem;
        margin-right: 0.1rem;
        line-height: 1rem;
      }
    }
  }
`;

const GuideWallets = ({ coin, titles, tableOnly, walletData }) => {
  const { t } = usetranslation("newguide");
  return (
    <div style={{ paddingBottom: `2rem` }}>
      {!tableOnly && <h2 id={titles["wallet"].id}>{titles["wallet"].name}</h2>}
      <Grid fluid style={{ marginBottom: `1.5rem` }}>
        <Row>
          {walletData.map((wallet) => {
            return (
              <Col key={wallet.slug} xs={12} sm={6}>
                <StyledCard>
                  <ImgWrapper>
                    <GuideArticleImg
                      src={`${wallet.attachments.data[0].attributes.url}`}
                      to={`${wallet.slug}`}
                      alt={wallet.name}
                      source={coin.slug}
                      width={150}
                      height={150}
                      content={`${wallet.name}_${coin.slug}`}
                    />
                  </ImgWrapper>
                  <h4 style={{ marginTop: `0.1rem` }}>{wallet.name}</h4>
                  <ProsList>
                    <ReactMarkdown>{wallet.description}</ReactMarkdown>
                  </ProsList>
                  <CtaWrapper>
                    <CtaLink
                      color={theme.accent_3}
                      text={t("buy_now")}
                      to={`${wallet.slug}`}
                      source={coin.slug}
                      content={`guide_wallet`}
                    />
                  </CtaWrapper>
                </StyledCard>
              </Col>
            );
          })}
        </Row>
      </Grid>

      {!tableOnly && <p>{t("wallet.intro", { symbol: coin.symbol })}</p>}
      {!tableOnly && <p>{t("wallet.details")}</p>}
    </div>
  );
};

export default GuideWallets;
