import React, { useState } from "react";
import styled from "styled-components";
import { BoxShadow, StyledLazyImg, BlurredImg } from "@/styles/style";
import { getMaskedRoute } from "@/utils/utils";
import Image from "next/image";
import "react-lazy-load-image-component/src/effects/blur.css";

const StyledImg = styled(Image)`
  ${BoxShadow};
  display: block;
  margin: 0 auto 0.8rem;
  opacity: ${(props) => (props.$isloaded ? 1 : 0)};
`;

const GuideArticleImg = ({
  to,
  src,
  alt,
  lazyload,
  source,
  content,
  width,
  height,
}) => {
  const [autoWidth, setAutoWidth] = useState(800);
  const [autoHeight, setAutoHeight] = useState(800);
  const [isLoaded, setIsLoaded] = useState(false);
  const handleSetSize = (img) => {
    setAutoWidth(img.naturalWidth);
    setAutoHeight(img.naturalHeight);
    setIsLoaded(true);
  };
  return (
    <a
      href={getMaskedRoute(to, {
        source: !!source ? source : ``,
        content: !!content ? content : ``,
      })}
      rel="nofollow noopener noreferrer"
      target="_blank"
      style={{
        display: `block`,
        textAlign: `center`,
        position: `relative`,
      }}
    >
      {lazyload ? (
        <StyledLazyImg src={`${src}`} alt={alt} effect="blur" />
      ) : (
        <StyledImg
          src={`${src}`}
          alt={alt}
          placeholder={`blur`}
          blurDataURL={BlurredImg}
          width={width ? width : autoWidth}
          height={height ? height : autoHeight}
          // onLoadingComplete={(img) => handleSetSize(img)}
          onLoad={(e) => handleSetSize(e.target)}
          $isloaded={isLoaded}
        />
      )}
    </a>
  );
};

export default GuideArticleImg;
