import dynamic from "next/dynamic";
import styled from "styled-components";
import { getMaskedRoute } from "@/utils/utils";
const CointrafficSidebar = dynamic(() =>
  import("@/components/stickyHeader/CointrafficSidebar", { ssr: false })
);

const bannerList = [
  {
    name: `Ledger`,
    id: `ledger`,
    src: `banner-ledger-vertical.jpg`,
  },
  // {
  //   name: `Crypto Ultimatum`,
  //   id: `cryptoultimatum`,
  //   src: `crypto-ultimatum.png`,
  // },
];

const BannerWrapper = styled.div`
  margin-bottom: 1em;
  img {
    max-width: 100%;
  }
`;
const Sidebar = () => {
  return (
    <aside>
      <CointrafficSidebar />
      {bannerList.map((item) => {
        return (
          <BannerWrapper key={item.id}>
            <a
              href={getMaskedRoute(item.id, { source: `news_sidebar` })}
              target={`_blank`}
            >
              <img src={`/static/${item.src}`} alt={item.name} />
            </a>
          </BannerWrapper>
        );
      })}
    </aside>
  );
};

export default Sidebar;
