import moment from "moment";
import axios from "axios";
import Link from "next/link";
import slugify from "slugify";
// import GuideCoinbase from "../components/guideArticle/guideCoinbase";
import GuideUphold from "@/components/guideArticle/guideUphold";
import GuidePancakeSwap from "@/components/guideArticle/guidePancakeSwap";
const utilsCjs = require("./utils-cjs");
const siteConfig = require("@/config/siteConfig");

export const priExchanges = [
  // {
  //   name: `coinbase`,
  //   component: GuideCoinbase,
  // },
  {
    name: `uphold`,
    component: GuideUphold,
  },
];

export const deFiExchanges = {
  pancakeswapv2: {
    component: GuidePancakeSwap,
  },
  pancakeswap: {
    component: GuidePancakeSwap,
  },
};

export const shortenerUrl = `https://go.cryptobuyingtips.com/`;

export const normaliseExchangeSlug = (slug) =>
  utilsCjs.normaliseExchangeSlug(slug);

export const getMaskedRoute = (name, utm) => {
  let medium = `cbt`;
  utm = !!utm ? utm : {};
  return `${shortenerUrl}${name.toLowerCase()}?utm_medium=${medium}${
    utm.hasOwnProperty(`source`)
      ? !!utm.source
        ? `&utm_source=${utm.source}`
        : ``
      : ``
  }${
    utm.hasOwnProperty(`content`)
      ? !!utm.content
        ? `&utm_term=${utm.content}`
        : ``
      : ``
  }`;
};

export const formatFigures = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const getTime = (time) => {
  return moment(time).format("lll");
};

export const checkChosenExchange = (coin) => {
  return utilsCjs.checkChosenExchange(coin);
};

export const getToc = (coin, t, baseCurrency) => {
  return {
    intro: {
      name: t("toc.whatIs", { symbol: coin.symbol }),
      id: `what-is-${coin.symbol.toLowerCase()}`,
    },
    step1: {
      name: t("toc.step_1"),
      id: `register-on-fiat-crypto-exchange`,
    },
    step2: {
      name: t("toc.step_2", { baseCurrency: baseCurrency }),
      id: `buy-coins-with-fiat-money`,
    },
    step3: {
      name: t("toc.step_3", { baseCurrency: baseCurrency }),
      id: `transfer-cryptos-to-altcoin-exchange`,
    },
    step4: {
      name: t("toc.step_4", { baseCurrency: baseCurrency }),
      id: `deposit-btc-to-exchange`,
    },
    step5: {
      name: t("toc.step_5", { symbol: coin.symbol }),
      id: `trade-${coin.symbol.toLowerCase()}`,
    },
    wallet: {
      name: t("toc.last_step", { symbol: coin.symbol }),
      id: `store-${coin.symbol.toLowerCase()}-securely-in-hardware-wallet`,
    },
    tools: {
      name: t("toc.other_tools", { symbol: coin.symbol }),
      id: `other-useful-tools`,
    },
    faq: {
      name: t("toc.faq"),
      id: `faq`,
      children: {
        cash: {
          name: t("toc.buy_with_cash", { symbol: coin.symbol }),
          id: `buy-${coin.symbol.toLowerCase()}-with-cash`,
        },
        eu: {
          name: t("toc.buy_in_eu", { symbol: coin.symbol }),
          id: `buy-${coin.symbol.toLowerCase()}-in-europe`,
        },
        alt: {
          name: t("toc.buy_with_cards", { symbol: coin.symbol }),
          id: `buy-${coin.symbol.toLowerCase()}-with-credit-cards`,
        },
      },
    },
    latestNews: {
      name: t("toc.latest_news", { symbol: coin.symbol }),
      id: `latest-news-${coin.symbol.toLowerCase()}`,
    },
    pricePrediction: {
      name: t("toc.price_prediction", { symbol: coin.symbol }),
      id: `${coin.symbol.toLowerCase()}-price-prediction`,
    },
  };
};

export const sponsoredExchanges = (coin, content) => {
  return [
    {
      exchange: {
        id: 302,
        name: `Gate.io`,
        slug: `gate-io`,
      },
      market_pair: `(sponsored)`,
      market_url: getMaskedRoute(`gateio`, {
        source: coin.slug,
        content: content,
      }),
    },
    {
      exchange: {
        id: 544,
        name: `MEXC Global`,
        slug: `mxc`,
      },
      market_pair: `(sponsored)`,
      market_url: getMaskedRoute(`mxc`, {
        source: coin.slug,
        content: content,
      }),
    },
    {
      exchange: {
        id: 270,
        name: `Binance`,
        slug: `binance`,
      },
      market_pair: `(sponsored)`,
      market_url: getMaskedRoute(`binance`, {
        source: coin.slug,
        content: content,
      }),
    },
  ];
};

export const getCoinMeta = async (slug) => {
  try {
    const req = await axios.get(
      `https://api.cryptobuyingtips.com/v1/cryptocurrency/quotes/latest?slug=${slug}`
    );
    const key = Object.keys(req.data.data)[0];
    let coinData = req.data.data[key];
    const metaReq = await axios.get(
      `https://api.cryptobuyingtips.com/v1/cryptocurrency/info?slug=${slug}`
    );
    coinData.meta = metaReq.data.data[key];
    const coinExReq = await axios.get(
      `https://api.cryptobuyingtips.com/v1/cryptocurrency/market-pairs/latest?aux=num_market_pairs,category,fee_type,market_url,notice,price_quote,effective_liquidity&convert=USD&slug=${slug}&limit=100&sort=cmc_rank&category=spot`
    );
    coinData.market_pairs = coinExReq.data.data.market_pairs;
    return coinData;
  } catch (e) {
    return {
      error: e.message,
    };
  }
};

// export const replaceStaticUrl = (url) => utilsCjs.replaceStaticUrl(url);

export const getAllBaseCurrencies = (coin) => {
  return coin.market_pairs
    .map((pair) => {
      let pairBaseCurrency = pair.market_pair.split("/").slice(-1)[0];
      let hasAcceptedBaseCurrency = siteConfig.baseCurrencies.find(
        (acceptedBaseCurrency) =>
          pairBaseCurrency.indexOf(acceptedBaseCurrency) >= 0
      );

      if (!!hasAcceptedBaseCurrency) {
        return hasAcceptedBaseCurrency;
      } else return undefined;
    })
    .filter((item) => typeof item !== "undefined")
    .sort((a, b) => {
      return (
        siteConfig.baseCurrencies.indexOf(a) -
        siteConfig.baseCurrencies.indexOf(b)
      );
    });
};

export const getAcceptedBaseCurrency = (coin) => {
  let availablePairs = getAllBaseCurrencies(coin);
  return availablePairs.length >= 1
    ? siteConfig.exchangeSpecificBaseCurrencies.indexOf(availablePairs[0]) < 0
      ? availablePairs[0]
      : siteConfig.baseCurrencies[0]
    : siteConfig.baseCurrencies[0];
};

// export const hasExchangeBaseCurrencyOnly = (coin) => {
//   return getAllBaseCurrencies(coin).every(
//     (base) =>
//       siteConfig.baseCurrencies.indexOf(base) >= 0 &&
//       siteConfig.exchangeSpecificBaseCurrencies.indexOf(base) >= 0
//   );
// };
export const pricePredictionConditions = (coin) => {
  let dayAdded = moment().diff(moment(coin.date_added), "days");
  let percentChange90 = !!coin.quote.USD.percent_change_90d
    ? coin.quote.USD.percent_change_90d
    : 0;
  const qualifiedRank = 300;
  const qualifiedDays = 90;
  if (dayAdded < qualifiedDays) {
    if (coin.cmc_rank > qualifiedRank) {
      return "day_added_90_rank_300";
    } else return "day_added_90_rank_in_300";
  } else {
    if (coin.cmc_rank > qualifiedRank) {
      if (percentChange90 > 0) {
        return "day_added_90_up_rank_300_percent_positive";
      } else return "day_added_90_up_rank_300_percent_negative";
    } else {
      if (percentChange90 > 0) {
        return "day_added_90_up_rank_in_300_percent_positive";
      } else return "day_added_90_up_rank_in_300_percent_negative";
    }
  }
};

export const buttonItemRender = (prefix) => {
  return function renderBtn(current, type, element) {
    if (
      type === "next" ||
      type === "prev" ||
      type === `jump-next` ||
      type === `jump-prev`
    ) {
      return (
        <Link
          legacyBehavior
          passHref={true}
          prefetch={false}
          href={`${prefix}${current}`}
        >
          <a></a>
        </Link>
      );
    }
    // if (type === "jump-prev" || type === "jump-next") {
    //   return <a></a>;
    // }
    return (
      <Link
        legacyBehavior
        passHref={true}
        prefetch={false}
        href={`${prefix}${current}`}
      >
        <a>{current}</a>
      </Link>
    );
  };
};
// export const usePublift = () => {
//   const { site } = useStaticQuery(
//     graphql`
//       query {
//         site {
//           siteMetadata {
//             usePublift
//           }
//         }
//       }
//     `
//   );
//
//   return site.siteMetadata.usePublift;
// };

// export const pubLiftIds = {
//   homeHeader: `22652838001`,
//   homeInCont1: `22652838004`,
//   homeInCont2: `22652838007`,
//   homeInCont3: `22652838010`,
//   homeInCont4: `22652838013`,
//   homeInCont5: `22652838016`,
//   homeMrec1: `22652838022`,
//   homeMrec2: `22652838019`,
//   homeVrec1: `22652782725`,
//   homeFooter: `22652838025`,
//   articleHeader: `22653316631`,
//   articleInCont1: `22652838028`,
//   articleInCont2: `22652838034`,
//   articleInCont3: `22652838031`,
//   articleInCont4: `22652838040`,
//   articleInCont5: `22652838037`,
//   articleInCont6: `22652838043`,
//   articleInCont7: `22652838046`,
//   articleInCont8: `22652838052`,
//   articleInCont9: `22652838049`,
//   articleInCont10: `22652838055`,
//   articleInCont11: `22652838058`,
//   articleInCont12: `22652838061`,
//   articleMrec1: `22653316634`,
//   articleMrec2: `22652838064`,
//   articleVrec1: `22652838067`,
//   articleFooter: `22652838070`,
//   stickyFooter: `22652838073`,
// };

export const parseAllLinks = (text, allLinks) => {
  return allLinks.reduce((accContent, keyword) => {
    const regex = new RegExp(`\\b(?<!!\\[)${keyword}(?!\\])\\b`, "gi");
    const link = `[${keyword}](https://go.cryptobuyingtips.com/${slugify(
      keyword.toLowerCase()
    )})`;
    return accContent.replace(regex, link);
  }, text);
};

export const elRenderer = {
  h1: "h3",
  h2: "h3",
  h3: "h3",
  a: ({ children, href }) => {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  },
};
