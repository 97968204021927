import React from "react";
import { useTranslation } from "next-i18next";
import CtaLink from "src/components/ctaLink";
import GuideArticleImg from "./guideArticleImg";
import { Grid, Row, Col } from "react-flex-grid-system";
import { StyledCard } from "@/styles/style";
import styled from "styled-components";
import GuideWallets from "./guideWallets";
// import theme from "../../const/theme";
import theme from "@/styles/theme";

const usExchanges = ["mxc", "gateio"];

const externalLinks = require("@/consts/externalLinks");
const exchangeBg = (exchange) =>
  externalLinks.filter((link) => link.name.toLowerCase() === exchange)[0];

const StyledImgWrapper = styled.div`
  width: 80px;
  margin: 16px auto 0;
`;
const GuidePancakeSwap = ({
  coin,
  baseCurrency,
  priExchange,
  priExchanges,
  usePl,
}) => {
  const { t } = usetranslation("newguide");
  return (
    <div>
      <p>
        {t("pancakeSwap.p_1", {
          baseCurrency: baseCurrency,
          coin: coin.symbol,
        })}
      </p>
      <h3>{t("pancakeSwap.h3_convert", { baseCurrency: baseCurrency })}</h3>
      <p>
        {t("pancakeSwap.p_2", {
          baseCurrency: baseCurrency,
          coin: coin.symbol,
        })}
      </p>
      <p>
        {t("pancakeSwap.p_3", {
          baseCurrency: baseCurrency,
          coin: coin.symbol,
        })}
      </p>
      <CtaLink
        color={`#F0B90B`}
        text={t(`sign_up_on`, { exchange: t(`exchanges.binance`) })}
        to={`binance`}
        source={coin.slug}
        content={`binance_cta_pancakeswap`}
      />
      <p>
        <strong>{t("pancakeSwap.p_4")}</strong>
      </p>
      <Grid fluid style={{ marginBottom: `1.5rem` }}>
        <Row>
          {usExchanges.map((exchange) => (
            <Col key={exchange} md={6} lg={4} style={{ marginBottom: 16 }}>
              <StyledCard>
                <StyledImgWrapper>
                  <GuideArticleImg
                    src={`/static/${exchange}_logo.png`}
                    to={`${exchange}`}
                    alt={t(`exchanges.${exchange}`)}
                    source={coin.slug}
                    width={80}
                    height={80}
                    content={`us_trader_${exchange}`}
                  />
                </StyledImgWrapper>
                <CtaLink
                  color={exchangeBg(exchange).bg}
                  to={exchange}
                  text={`${t("sign_up_pri", {
                    exchange: t(`exchanges.${exchange}`),
                  })}`}
                  source={coin.slug}
                  content={`us_trader_cta_${exchange}`}
                />
              </StyledCard>
            </Col>
          ))}
        </Row>
      </Grid>
      <p>
        {t("pancakeSwap.p_5", {
          baseCurrency: baseCurrency,
          coin: coin.symbol,
          priExchange: t(`exchanges.${priExchanges[priExchange].name}`),
        })}
      </p>
      <h3>{t("pancakeSwap.h3_transfer_bnb")}</h3>
      <p>
        {t("pancakeSwap.p_6", {
          coin: coin.symbol,
        })}
      </p>
      {/*<GuideWallets tableOnly={true} coin={coin} />*/}
      <p>{t("pancakeSwap.p_7")}</p>
      <h3>{t("pancakeSwap.h3_add_metamask")}</h3>
      <p>{t("pancakeSwap.p_8")}</p>
      <GuideArticleImg
        src={`/static/metamask-get-started.jpg`}
        to={`metamask`}
        alt={t(`MetaMask`)}
        source={coin.slug}
        content={`metamask_img`}
      />
      <p>{t("pancakeSwap.p_9")}</p>
      <GuideArticleImg
        src={`/static/metamask-create-a-wallet.jpg`}
        to={`metamask`}
        alt={t(`MetaMask`)}
        source={coin.slug}
        content={`metamask_img`}
      />
      <p>{t("pancakeSwap.p_10")}</p>
      <GuideArticleImg
        src={`/static/metamask-password.jpg`}
        to={`metamask`}
        alt={t(`MetaMask`)}
        source={coin.slug}
        content={`metamask_img`}
      />
      <p>{t("pancakeSwap.p_11")}</p>
      <GuideArticleImg
        src={`/static/cryptosteel.png`}
        to={`cryptosteel-capsule-solo`}
        alt={`CryptoSteel Capsule Solo`}
        source={coin.slug}
        content={`cryptosteel`}
      />
      <CtaLink
        color={theme.accent_3}
        text={t("buy_now")}
        to={`cryptosteel-capsule-solo`}
        source={coin.slug}
        content={`cryptosteel_cta`}
      />
      <p>{t("pancakeSwap.p_12")}</p>
      <GuideArticleImg
        src={`/static/metamask-interface.jpg`}
        to={`metamask`}
        alt={t(`MetaMask`)}
        source={coin.slug}
        content={`metamask_img`}
      />
      <p>{t("pancakeSwap.p_13")}</p>
      <GuideArticleImg
        src={`/static/pancakeswap-wallets-dapp.jpg`}
        to={`pancakeswap`}
        alt={t(`exchanges.pancakeswap`)}
        source={coin.slug}
        content={`pancakeswap_img`}
      />
      <p>{t("pancakeSwap.p_14")}</p>
      <GuideArticleImg
        src={`/static/metamask-copy-address.jpg`}
        to={`metamask`}
        alt={t(`MetaMask`)}
        source={coin.slug}
        content={`metamask_img`}
      />
      <p>{t("pancakeSwap.p_15")}</p>
      <GuideArticleImg
        src={`/static/withdraw_bnb.jpg`}
        to={`metamask`}
        alt={t(`MetaMask`)}
        source={coin.slug}
        content={`metamask_img`}
      />
      <p>
        {t("pancakeSwap.p_16", {
          coin: coin.symbol,
        })}
      </p>
      <p>{t("pancakeSwap.p_17")}</p>
      <GuideArticleImg
        src={`/static/pancakeswap-trading-tokens.jpg`}
        to={`pancakeswap`}
        alt={t(`exchanges.pancakeswap`)}
        source={coin.slug}
        content={`pancakeswap_img`}
      />
      <p>{t("pancakeSwap.p_18")}</p>
      <GuideArticleImg
        src={`/static/pancakeswap_swapscreen.png`}
        to={`pancakeswap`}
        alt={t(`exchanges.pancakeswap`)}
        source={coin.slug}
        content={`pancakeswap_img`}
      />
      <p>
        {t("pancakeSwap.p_19", {
          coin: coin.symbol,
        })}
      </p>
      <GuideArticleImg
        src={`/static/pancakeswap-transaction-submitted.jpg`}
        to={`pancakeswap`}
        alt={t(`exchanges.pancakeswap`)}
        source={coin.slug}
        content={`pancakeswap_img`}
      />
    </div>
  );
};

export default GuidePancakeSwap;
