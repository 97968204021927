import styled from "styled-components";
import Head from "next/head";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import SectionWrapper from "@/components/SectionWrapper";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";
const { site } = require("@/config/siteConfig");

const StyledMain = styled.main`
  background-color: ${(props) => props.theme.grey_6};
  min-height: 90vh;
`;

const languages = require("@/config/siteConfig").languages.filter(
  (lang) => lang !== `en`
);
const gTransLanguages = require("@/config/siteConfig").gTransLanguages;

const generateUrl = (siteUrl, pathname, locale, hasI18n = false) => {
  // unified url patterns
  // return hasI18n
  //   ? `${siteUrl}/${locale}${pathname}`
  //   : `https://${locale}.${siteUrl}${pathname}`;
  const url = new URL(
    `${
      siteUrl.startsWith(`http`) ? `` : `https://`
    }${siteUrl}/${locale}${pathname}`
  );
  return url.href;
};
const Layout = ({
  children,
  title,
  description,
  hasI18n = false,
  noSidebar = false,
}) => {
  const [ssr, setSsr] = useState(true);
  const router = useRouter();
  const mainLanguages = hasI18n ? languages : [];
  const secLanguages = hasI18n
    ? gTransLanguages
    : languages.concat(gTransLanguages);
  useEffect(() => {
    setSsr(false);
    // if (typeof window !== `undefined` && !ssr) {
    // window.coinzilla_sticky = window.coinzilla_sticky || [];
    // function czilla() {
    //   window.coinzilla_sticky.push(arguments);
    // }
    // czilla("9165e1faf1d2101c937");
    // const cointraffic = document.createElement("script");
    // cointraffic.src = `https://${process.env.COINTRAFFIC_HOST}/js/?wkey=1fNAI2QNpG`;
    // cointraffic.async = true;
    // document.body.appendChild(cointraffic);
    // }
  }, [ssr]);
  return (
    <>
      <Head>
        <title>{`${title} | ${site.siteMetadata.title}`}</title>
        <link
          rel={`alternate`}
          hrefLang={`x-default`}
          href={`https://${process.env.SITE_URL}${router.asPath}`}
        />
        <link
          rel={`canonical`}
          href={`https://${process.env.SITE_URL}${router.asPath}`}
        />
        {mainLanguages.length > 0 &&
          mainLanguages.map((lang, index) => {
            return (
              <link
                rel={`alternate`}
                hrefLang={lang}
                key={index}
                href={generateUrl(
                  process.env.SITE_URL,
                  router.asPath,
                  lang,
                  true
                )}
              />
            );
          })}
        {secLanguages.map((lang, index) => {
          return (
            <link
              rel={`alternate`}
              hrefLang={lang}
              key={index}
              href={generateUrl(process.env.SITE_URL, router.asPath, lang)}
            />
          );
        })}
      </Head>
      <Header />
      <StyledMain>
        <SectionWrapper noSidebar={noSidebar}>{children}</SectionWrapper>
      </StyledMain>
      <Footer />
      <div style={{ display: `none` }}>
        {!ssr & (process.env.NODE_ENV === `production`) && (
          <>
            <Script
              src={`https://coinzillatag.com/lib/sticky.js`}
              strategy={`lazyOnload`}
              onLoad={() => {
                window.coinzilla_sticky = window.coinzilla_sticky || [];
                function czilla() {
                  window.coinzilla_sticky.push(arguments);
                }
                czilla("9165e1faf1d2101c937");
              }}
            />
            <Script
              async
              src={`https://${process.env.COINTRAFFIC_HOST}/js/?wkey=1fNAI2QNpG`}
              strategy={`lazyOnload`}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Layout;
