import styled from "styled-components";
import Logo from "@/assets/logo.svg";
import { Container, Row, Col } from "styled-bootstrap-grid";
import Headroom from "@/components/stickyHeader/stickyHeader";
import { StyledRightCol } from "@/styles/style";
import Link from "next/link";
import PriceTicker from "@/components/PriceTicker";

const HeaderInner = styled(Container)`
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.theme.pageWidth};
  padding: 0.5em;
`;

const StyledRow = styled(Row)`
  align-items: center;
  max-width: 100%;
  margin: 0;
`;

const HeaderWrapper = styled.header`
  background: ${(props) => props.theme.primary};
`;

const StyledLogo = styled(Logo)`
  width: 3em;
  vertical-align: middle;
`;

const StyledLink = styled(Link)`
  display: inline-block;
`;

const StyledLinkInner = styled.a`
  display: inline-block;
`;

const StyledSiteName = styled.span`
  display: none;
  @media (min-width: 768px) {
    display: inline-block;
    vertical-align: middle;
    color: ${(props) => props.theme.white};
    padding-left: 0.5em;
    font-size: 1.2em;
  }
`;
const Header = () => {
  return (
    <Headroom suppressHydrationWarning={true}>
      <HeaderWrapper>
        <HeaderInner fluid>
          <StyledRow>
            <Col noGutter={true} xs={3} md={6}>
              <StyledLink
                style={{
                  boxShadow: `none`,
                  textDecoration: `none`,
                  color: `inherit`,
                }}
                href={`/`}
                passHref
                prefetch={false}
                legacyBehavior
              >
                <StyledLinkInner>
                  <StyledLogo />
                  <StyledSiteName>Crypto Buying Tips</StyledSiteName>
                </StyledLinkInner>
              </StyledLink>
            </Col>
            <StyledRightCol noGutter={true} xs={9} md={6}>
              <PriceTicker />
            </StyledRightCol>
          </StyledRow>
        </HeaderInner>
      </HeaderWrapper>
    </Headroom>
  );
};

export default Header;
