import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { ResetUl } from "@/styles/style";
import { getMaskedRoute } from "@/utils/utils";

const TickerList = styled(ResetUl)`
  list-style-type: none;
  display: flex;
  color: ${(props) => props.theme.white};
  margin-bottom: 0;
  font-weight: lighter;
  justify-content: flex-end;

  @media (max-width: 480px) {
    overflow-x: scroll;
    justify-content: flex-start;
  }
`;

const Wrapper = styled.a`
  display: block;
`;

const TickerLi = styled.li`
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  margin-left: 0.8em;
  margin-right: 0.8em;
  opacity: ${(props) => (props.isLoading ? 0.4 : 1)};
  transition: opacity 0.4s;
`;

const PercentChange = styled.div`
  font-size: 0.8em;
  color: ${(props) => (props.value > 0 ? props.theme.up : props.theme.down)};
`;

const Price = styled.div`
  font-size: 1em;
  opacity: 0.8;
`;

const TickerName = styled.div`
  font-weight: 500;
  opacity: 0.4;
  font-size: 0.8em;
`;

const PriceTicker = () => {
  const watchedCoins = ["BTC", "ETH", "LTC", "BCH"];
  const tickerApi = `https://production.api.coindesk.com/v2/price/ticker?assets=${watchedCoins.join()}`;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getPrice = async () => {
      setIsLoading(true);
      try {
        return await axios.get(tickerApi);
      } catch (e) {
        setError(e);
      }
    };
    const callGetPrice = () => {
      getPrice().then((rsp) => {
        setData(rsp.data);
        setIsLoading(false);
      });
    };

    callGetPrice();

    const update = setInterval(() => {
      callGetPrice();
    }, 1000 * 5);

    return () => {
      clearInterval(update);
    };
  }, [tickerApi]);

  if (!data || error !== null) {
    return null;
  }
  const priceData = data.data;
  const formatFigures = (number) => number.toFixed(2);
  const sign = (number) => (number > 0 ? `+` : ``);
  return (
    <Wrapper
      href={getMaskedRoute(`binance`, { content: `price_ticker` })}
      target={`_blank`}
    >
      <TickerList>
        {watchedCoins.map((coin) => {
          return (
            <TickerLi key={coin} isLoading={isLoading}>
              <TickerName>{coin}</TickerName>
              <Price>{`$${formatFigures(priceData[coin].ohlc.c)}`}</Price>
              <PercentChange
                value={formatFigures(priceData[coin].change.percent)}
              >
                {`${sign(priceData[coin].change.percent)}${formatFigures(
                  priceData[coin].change.percent
                )}%`}
              </PercentChange>
              <span>{isLoading}</span>
            </TickerLi>
          );
        })}
      </TickerList>
    </Wrapper>
  );
};

export default PriceTicker;
