const siteConfig = require("@/config/siteConfig");
const uniqBy = require("lodash/uniqBy");
const orderBy = require("lodash/orderBy");
const punycode = require("punycode/");
const slugify = require("slugify");
require("dotenv").config();
const fetcher = async (queryString) => {
  try {
    console.log("strapi url", process.env.STRAPI_URL);
    return await fetch(`${process.env.STRAPI_URL}${queryString}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.STRAPI_TOKEN}`,
        "Accept-Encoding": `gzip,br,deflate,compress`,
        "CF-Access-Client-Id": process.env.CF_ACCESS_CLIENT_ID,
        "CF-Access-Client-Secret": process.env.CF_ACCESS_CLIENT_SECRET,
      },
    });
  } catch (e) {
    console.log("error", e);
    return null;
  }
};

const formatCurrency = (figure, currency = "USD", locale = "en-US") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumSignificantDigits: 3,
  }).format(figure);
};

const normaliseExchangeSlug = (slug) => {
  return slug.replace("-", "");
};

const transformImgUrl = (url) => {
  if (!!url) {
    const transformedUrl = new URL(url);
    if (transformedUrl.host === `crypto.snapi.dev`) {
      transformedUrl.host = process.env.CRYPTO_NEWS_IMG_URL;
    }
    return transformedUrl.toString();
  } else {
    return undefined;
  }
};

const getAffiliateLink = (slug) => {
  return new URL(slug, `https://go.cryptobuyingtips.com`).toString();
};

const checkChosenExchange = (coin) => {
  let exchanges = uniqBy(coin.market_pairs, function (marketPair) {
    return marketPair.exchange.slug;
  });
  return orderBy(
    exchanges
      .map((exchange) => exchange.exchange.slug.replace("-", ""))
      .filter((exchange) =>
        siteConfig.chosenExchanges.includes(exchange.replace("-", ""))
      ),
    [(exchange) => siteConfig.chosenExchanges.indexOf(exchange)],
    ["asc"]
  );
};
const getCoinPricePageUrl = (symbol, name, lang) => {
  let locale = !lang || lang === "en" ? "" : `/${lang}`;
  const slug = `${punycode.toASCII(
    symbol.toLowerCase().split(" ").join("-")
  )}-${punycode.toASCII(name.toLowerCase().split(" ").join("-"))}`;
  return `${locale}/coin/${slug}`;
};

module.exports = {
  fetcher,
  formatCurrency,
  normaliseExchangeSlug,
  transformImgUrl,
  getAffiliateLink,
  checkChosenExchange,
  getCoinPricePageUrl,
  getLanguageSubdomain: function (url, lang) {
    return url.replace("://", `://${lang}.`);
  },
};
