const siteConfig = {
  siteUrl: `https://cryptobuyingtips.com`,
  redirectUrl: `https://go.cryptobuyingtips.com/`,
  postPerPage: 24,
  sitemapPageSize: 200,
  imageQuality: 40,
  guidesImagesDir: "./static/guides/",
  guideScreenshotDir: "./static/guides/screenshots/",
  publiftDev: process.env.NODE_ENV !== `production`,
  publiftMobileBreakpoint: 1279,
  languages: [
    "ar",
    "bn",
    "zh-TW",
    "ko",
    "tr",
    "hi",
    "ja",
    "id",
    "pt",
    "ru",
    "zh-CN",
    "es",
    "de",
    "nl",
    "fr",
    "en",
    "bg",
    "hr",
    "cs",
    "da",
    "fi",
    "el",
    "it",
    "no",
    "pl",
    "ro",
    "sv",
    "ca",
    "tl",
    "iw",
    "lv",
    "lt",
    "sr",
    "sk",
    "sl",
    "uk",
    "vi",
    "sq",
    "et",
    "gl",
    "hu",
    "mt",
    "th",
    "fa",
    "af",
    "ms",
    "sw",
    "ga",
    "cy",
    "be",
    "is",
    "mk",
    "yi",
    "hy",
    "az",
    "eu",
    "ka",
    "ht",
    "ur",
    "bs",
    "ceb",
    "eo",
    "gu",
    "ha",
    "hmn",
    "ig",
    "jw",
    "kn",
    "km",
    "lo",
    "la",
    "mi",
    "mr",
    "mn",
    "ne",
    "pa",
    "so",
    "ta",
    "te",
    "yo",
    "zu",
    "my",
    "ny",
    "kk",
    "mg",
    "ml",
    "si",
    "st",
    "su",
    "tg",
    "uz",
    "am",
    "co",
    "ku",
    "ky",
    "lb",
    "ps",
    "sn",
    "sd",
    "fy",
    //   disabling a few languages because vercel doesn't support more than 100
    // "sm",
    // "gd",
    // "haw",
    // "xh",
  ],
  //languages that are available in google translate
  // gTransLanguages: [
  //   "hi",
  //   "bg",
  //   "zh-CN",
  //   "zh-TW",
  //   "hr",
  //   "cs",
  //   "da",
  //   "fi",
  //   "el",
  //   "it",
  //   "no",
  //   "pl",
  //   "ro",
  //   "sv",
  //   "ca",
  //   "tl",
  //   "iw",
  //   "lv",
  //   "lt",
  //   "sr",
  //   "sk",
  //   "sl",
  //   "uk",
  //   "vi",
  //   "sq",
  //   "et",
  //   "gl",
  //   "hu",
  //   "mt",
  //   "th",
  //   "fa",
  //   "af",
  //   "ms",
  //   "sw",
  //   "ga",
  //   "cy",
  //   "be",
  //   "is",
  //   "mk",
  //   "yi",
  //   "hy",
  //   "az",
  //   "eu",
  //   "ka",
  //   "ht",
  //   "ur",
  //   "bn",
  //   "bs",
  //   "ceb",
  //   "eo",
  //   "gu",
  //   "ha",
  //   "hmn",
  //   "ig",
  //   "jw",
  //   "kn",
  //   "km",
  //   "lo",
  //   "la",
  //   "mi",
  //   "mr",
  //   "mn",
  //   "ne",
  //   "pa",
  //   "so",
  //   "ta",
  //   "te",
  //   "yo",
  //   "zu",
  //   "my",
  //   "ny",
  //   "kk",
  //   "mg",
  //   "ml",
  //   "si",
  //   "st",
  //   "su",
  //   "tg",
  //   "uz",
  //   "am",
  //   "co",
  //   "haw",
  //   "ku",
  //   "ky",
  //   "lb",
  //   "ps",
  //   "sm",
  //   "gd",
  //   "sn",
  //   "sd",
  //   "fy",
  //   "xh",
  // ],
  gTransLanguages: [],
  chosenExchanges: [
    "gateio",
    "mxc",
    "bitmart",
    // "bitforex",
    // "okex",
    "binance",
    // "huobiglobal",
    // "kucoin",
    // "poloniex",
    // "probitexchange",
    "pancakeswapv2",
    "pancakeswap",
    "liquid",
    // "bitfinex",
    // "oceanex",
    // "bittrex",
    // "bitmax",
    // "mercatox",
    // "p2pb2b",
    // "tokok",
    // "yobit",
    // referral is stopped for kucoin, making it the last
    // "bilaxy",
    // "bilaxy",
  ],
  defaultExchange: "gateio",
  chosenDefiExchanges: ["pancakeswapv2", "pancakeswap"],
  baseCurrencies: ["BTC", "ETH", "USDT", "BNB"],
  exchangeSpecificBaseCurrencies: ["BNB"],
  deFiExchanges: ["1inch-exchange"],
  activeAutoSocials: [
    "bitclout",
    "telegraph",
    "telegramChannel",
    // "linktree",
    "instapaper",
    // "gab",
    // "pinterest",
    // "medium",
    // "twitter",
    // "facebook",
  ],
  // list of coins that need to be re-fetched every time site is updated
  recurringCoins: [
    5994, // shiba
    10804, // floki
    5426, // solana
    6636, // polkadot
    9436, // dogelon
    10498, // saitama
  ],
  skippedCoinsIds: [
    723,
    11571,
    7257, // fake ape coin
    // below are problematic ids
    // 2468, 3904, 2643,
  ],
  // temporary skipping these exchanges as affiliate is not working
  tempMediumSkippedExchange: [],
  btnPositions: {
    binance: {
      x: 60,
      y: 430,
      w: 680,
      h: 70,
      color: "white",
    },
    kucoin: {
      x: 22,
      y: 590,
      w: 722,
      h: 72,
      color: "white",
    },
    mercatox: {
      x: 40,
      y: 40,
      w: 762,
      h: 88,
      color: "black",
    },
    hotbit: {
      x: 37,
      y: 611,
      w: 853,
      h: 68,
      color: "white",
    },
    bittrex: {
      x: 17,
      y: 750,
      w: 600,
      h: 65,
      color: "white",
    },
    gateio: {
      x: 17,
      y: 653,
      w: 845,
      h: 83,
      color: "white",
    },
    bitforex: {
      x: 31,
      y: 687,
      w: 928,
      h: 101,
      color: "white",
    },
    yobit: {
      x: 39,
      y: 415,
      w: 641,
      h: 68,
      color: "white",
    },
    tokok: {
      x: 27,
      y: 446,
      w: 522,
      h: 59,
      color: "white",
    },
    liquid: {
      x: 21,
      y: 613,
      w: 555,
      h: 93,
      color: "white",
    },
    bitfinex: {
      x: 24,
      y: 334,
      w: 644,
      h: 63,
      color: "white",
    },
    p2pb2b: {
      x: 210,
      y: 282,
      w: 560,
      h: 80,
      color: "white",
    },
    okex: {
      x: 18,
      y: 554,
      w: 598,
      h: 85,
      color: "white",
    },
    huobiglobal: {
      x: 28,
      y: 396,
      w: 570,
      h: 68,
      color: "white",
    },
    probitexchange: {
      x: 41,
      y: 413,
      w: 418,
      h: 66,
      color: "white",
    },
    poloniex: {
      x: 90,
      y: 530,
      w: 640,
      h: 80,
      color: "black",
    },
    mxc: {
      x: 35,
      y: 460,
      w: 680,
      h: 65,
      color: "white",
    },
    oceanex: {
      x: 15,
      y: 460,
      w: 485,
      h: 82,
      color: "white",
    },
    bitmax: {
      x: 43,
      y: 511,
      w: 730,
      h: 66,
      color: "white",
    },
    bitmart: {
      x: 13,
      y: 440,
      w: 473,
      h: 74,
      color: "white",
    },
    bilaxy: {
      x: 50,
      y: 395,
      w: 730,
      h: 75,
      color: "white",
    },
  },
  puppeteerScreen: {
    width: 1280,
    height: 800,
  },
  site: {
    siteMetadata: {
      title: `Crypto Buying Tips`,
      author: `Kenneth Learned`,
      description: `Beginners' guide to purchase cryptocurrencies and related products. Latest news on blockchain related topics.`,
    },
  },
};

module.exports = siteConfig;
