import React, { useState } from "react";
import CtaLink from "@/components/ctaLink";
import GuideArticleImg from "./guideArticleImg";
import styled from "styled-components";
import theme from "@/styles/theme";
import { useTranslation } from "next-i18next";

const externalLinks = require("@/consts/externalLinks");

const meta = externalLinks.filter((link) => link.name === "UpHold")[0];

const ShowDetails = styled.div`
  text-align: center;
  cursor: pointer;
  margin-bottom: 1.5rem;
  color: ${theme.accent_2};
`;

const DetailSteps = styled.div`
  display: ${(props) => (props.showDetail ? `block` : `none`)};
`;

const GuideUphold = ({ coin, titles, baseCurrency }) => {
  const { t } = usetranslation("newguide");
  const [showDetail, setShowDetail] = useState(false);
  return (
    <div className={"uphold"}>
      {/*{ Step 1}*/}
      <GuideArticleImg
        src="/static/uphold.png"
        to="uphold"
        alt={coin.symbol}
        source={coin.slug}
        content={`uphold`}
      />

      <p>{t("uphold.intro")}</p>

      <ul>
        {[...Array(8)].map((e, i) => (
          <li key={i}>{t(`uphold.advantages.point_${i}`)}</li>
        ))}
      </ul>

      <CtaLink
        color={meta.bg}
        text={t("uphold.cta")}
        to={`uphold`}
        source={coin.slug}
        content={`cta1`}
      />

      <ShowDetails onClick={() => setShowDetail(!showDetail)}>
        {t("details_steps", { display: showDetail ? t("hide") : t("show") })}
      </ShowDetails>

      <DetailSteps showDetail={showDetail}>
        <GuideArticleImg
          src="/static/uphold-register.png"
          to="uphold"
          alt={coin.symbol}
          source={coin.slug}
          content={`uphold_register`}
        />
        <p>{t("uphold.get_started")}</p>

        <GuideArticleImg
          src="/static/uphold-2fa.png"
          to="uphold"
          alt={coin.symbol}
          source={coin.slug}
          content={`uphold_cta`}
        />

        <p>{t("uphold.two_fa")}</p>

        <GuideArticleImg
          src="/static/uphold-kyc.png"
          to="uphold"
          alt={coin.symbol}
          source={coin.slug}
          content={`uphold_2fa`}
        />

        <p>{t("uphold.kyc")}</p>
      </DetailSteps>
      <h2 id={titles["step2"].id}>{titles["step2"].name}</h2>

      <GuideArticleImg
        src="/static/uphold-payments.png"
        to="uphold"
        alt={coin.symbol}
        source={coin.slug}
        content={`uphold_payments`}
      />

      <p>{t("uphold.payment")}</p>

      <GuideArticleImg
        src="/static/uphold-buy.png"
        to="uphold"
        alt={coin.symbol}
        source={coin.slug}
        content={`uphold_buy`}
      />

      <p>
        {t("uphold.trade", {
          baseCurrency: t(`baseCurrencies.${baseCurrency.toLowerCase()}`),
        })}
      </p>
    </div>
  );
};

export default GuideUphold;
