import React from "react";
import styled from "styled-components";
import { getMaskedRoute } from "@/utils/utils";

const StyledLinkBtn = styled.a`
  margin: ${(props) => (props.noMargin ? `1rem` : `1.5em auto 2.5em`)};
  padding: ${(props) => (props.inline ? `0.6em 1.8em` : `0.6em 0.8em`)};
  background-color: ${(props) =>
    props.color ? props.color : props.theme.accent_3};
  text-align: center;
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  max-width: 25em;
  color: ${(props) => props.theme.white};
  border-radius: 2em;
  text-transform: uppercase;
  transition: all 0.3s ease-out;

  && {
    font-weight: 400;
  }

  box-shadow: 0 2px 2px
      rgba(
        ${(props) =>
          props.shadow ? props.theme.hexToRgb(props.shadow) : `0,0,0`},
        0.06
      ),
    0 4px 4px
      rgba(
        ${(props) =>
          props.shadow ? props.theme.hexToRgb(props.shadow) : `0,0,0`},
        0.1
      ),
    0 5px 5px
      rgba(
        ${(props) =>
          props.shadow ? props.theme.hexToRgb(props.shadow) : `0,0,0`},
        0.2
      ),
    0 6px 6px
      rgba(
        ${(props) =>
          props.shadow ? props.theme.hexToRgb(props.shadow) : `0,0,0`},
        0.08
      );

  :hover {
    transform: scale(1.05);
    transform-origin: center bottom;
    box-shadow: 0 4px 4px
        rgba(
          ${(props) =>
            props.shadow ? props.theme.hexToRgb(props.shadow) : `0,0,0`},
          0.14
        ),
      0 6px 6px
        rgba(
          ${(props) =>
            props.shadow ? props.theme.hexToRgb(props.shadow) : `0,0,0`},
          0.16
        ),
      0 8px 8px
        rgba(
          ${(props) =>
            props.shadow ? props.theme.hexToRgb(props.shadow) : `0,0,0`},
          0.18
        ),
      0 10px 10px
        rgba(
          ${(props) =>
            props.shadow ? props.theme.hexToRgb(props.shadow) : `0,0,0`},
          0.18
        );
  }
`;

const CtaLink = ({
  color,
  text,
  to,
  noMargin,
  external,
  source,
  content,
  inline,
}) => {
  return (
    <div>
      <StyledLinkBtn
        href={
          !external
            ? getMaskedRoute(to, {
                source: !!source ? source : ``,
                content: !!content ? content : ``,
              })
            : to
        }
        color={color}
        shadow={color}
        rel="nofollow noopener noreferrer"
        target="_blank"
        noMargin={noMargin}
        inline={inline}
      >
        {text}
      </StyledLinkBtn>
    </div>
  );
};

export default CtaLink;
