import styled, { css } from "styled-components";
import theme from "./theme";
import Image from "next/image";
// import Img from "gatsby-image";
import { Container, Col, Row } from "styled-bootstrap-grid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DataTable from "react-data-table-component";
import { Tab, TabList, TabPanel } from "react-tabs";
//
const waterMark = css`
  &:before {
    display: block;
    position: absolute;
    bottom: 8px;
    right: 8px;
    content: "https://cryptobuyingtips.com/";
    color: white;
    font-size: 10px;
  }
`;
export const ImgWrapper = styled.div`
  background-color: ${theme.accent_3};
  position: relative;
  height: 120px;
  overflow: hidden;

  .lazy-load-image-background {
    width: 100%;
    height: 100%;
  }
`;
export const StyledImg = styled(Image)`
  max-height: ${(props) => (props.isGuide ? `150px` : `none`)};
  mix-blend-mode: ${(props) => (props.isGuide ? `normal` : `multiply`)};

  @media (min-width: 768px) {
    max-height: ${(props) => (props.isGuide ? `250px` : `300px`)};
  }
`;

export const ResetUl = styled.ul`
  margin-top: 0;
  padding-left: 0;
  @media only screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

export const StyledGrid = styled(Container)`
  width: 100%;
  padding-left: ${(props) => (props.padding ? `16px` : `0!important`)};
  padding-right: ${(props) => (props.padding ? `16px` : `0!important`)};
`;

export const StyledRightCol = styled(Col)`
  @media (max-width: 480px) {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 3em;
      height: 100%;
      z-index: 1;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        ${(props) => props.theme.primary} 100%
      );
    }
  }
`;
//
export const GuideArticleWrapper = styled.div`
  a {
    font-weight: bold;
  }

  &.cpr {
    display: none;
  }
`;

export const BoxShadow = css`
  box-shadow: 0 1px 1px
      rgba(
        ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
        0.02
      ),
    0 2px 2px
      rgba(
        ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
        0.04
      ),
    0 3px 3px
      rgba(
        ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
        0.06
      ),
    0 6px 6px
      rgba(
        ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
        0.02
      );

  :hover {
    box-shadow: 0 1px 1px
        rgba(
          ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
          0.04
        ),
      0 2px 2px
        rgba(
          ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
          0.06
        ),
      0 4px 4px
        rgba(
          ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
          0.08
        ),
      0 8px 8px
        rgba(
          ${(props) => (props.shadow ? theme.hexToRgb(props.shadow) : `0,0,0`)},
          0.08
        );
  }
`;

export const BlogPostImgWrapper = styled(ImgWrapper)`
  margin-top: 0.5rem;
  height: auto;
`;
export const GuideImage = styled.div`
  height: 0;
  padding-bottom: 37.5%;
  background-color: ${theme.accent_3};
  background-image: ${(props) => `url(${props.imgsrc})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200px 200px;
  position: relative;
`;
export const ArticleTitle = styled.h1`
  font-style: italic;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
  font-size: 1.6rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
export const SocialWrapper = styled.div`
  margin: 0.5rem 0;
  button {
    margin-right: 0.1rem;
    transition: opacity 0.3s ease-in-out;
    &:focus {
      outline: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ListWrapper = styled.div`
  margin-bottom: 0.5rem;
  border-bottom: solid 1px ${theme.grey_4};
`;

export const StyledLazyImg = styled(LazyLoadImage)`
  ${BoxShadow};
  display: block;
  margin: 0 auto 0.8rem;
`;

export const StyledDataTable = styled(DataTable)`
  * {
    font-size: 1rem;
  }
  margin: 0 auto 1rem;
  border-radius: 0.7rem;
  @media (min-width: 1200px) {
    width: 50%;
  }
  ${BoxShadow}
`;
export const TabImage = styled.div`
  width: 1rem;
  height: 1rem;
  margin-bottom: 0.1rem !important;
  display: inline-block;
  vertical-align: middle;
`;

export const StyledTabList = styled(TabList)`
  margin-bottom: 1.5rem;
  padding: 0;
  border-bottom: 1px solid ${theme.grey_4};
  display: flex;
  overflow-x: auto;

  @media only screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

export const StyledTab = styled(Tab)`
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  list-style: none;
  padding: 0.4rem 1rem;
  font-size: 0.7rem;
  background: ${(props) =>
    props.selected ? `${theme.grey_2}` : `${theme.grey_3}`};
  cursor: pointer;
  color: ${(props) => (props.selected ? `${theme.white}` : `inherit`)};
  margin-bottom: 0;
`;

export const StyledPanel = styled(TabPanel)`
  display: ${(props) => (props.selected ? `block` : `none`)};
`;

export const FullRow = styled(Row)`
  width: 100%;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const StyledExchangeImgWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;
export const StyledExchangeWrapper = styled.div`
  display: flex;
`;
export const StyledExchangeLink = styled.a`
  display: flex;
`;

export const StyledPercentage = styled.div`
  display: inline-block;
  background: ${(props) => theme.upDown(props.value)};
  color: white;
  border-radius: 3px;
  padding: 0.2rem;
`;

export const CoinHeaderIcon = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.5rem;
`;

export const StyledCoinHeader = styled.h3`
  margin-top: 0.5rem;
  display: flex;
  font-size: 1.5rem;

  small {
    background: ${theme.grey_4};
    font-weight: normal;
    padding: 0.2rem 0.3rem;
    color: ${theme.grey_2};
    margin-left: 0.5rem;
    border-radius: 3px;
    font-size: 1rem;
  }
`;

export const PercentWrapper = styled.div`
  display: flex;
  align-items: center;
  h3 {
    margin: 0.5rem 0.3rem 0.5rem 0;
  }

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const ArticleWrapper = styled.article`
  padding-top: 2rem;
`;

export const FiguresCol = styled(Col)`
  @media (min-width: 768px) {
    text-align: right;
  }
`;

export const FiguresSubhead = styled.div`
  color: ${theme.grey_2_5};
  font-size: 0.8rem;
  margin-top: 0.3rem;
`;

export const StyledTradingViewWrapper = styled.div`
  height: 40vh;
  @media (min-width: 1200px) {
    height: 50vh;
  }
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  text-align: center;
  background: white;
  position: relative;
  border-radius: 0.7rem;
  margin-bottom: 0.3rem;

  @media (min-width: 480px) {
    height: 100%;
  }
  ${BoxShadow}
`;

export const StyledCol = styled(Col)`
  margin-top: ${(props) => (props.hidedesc ? "0" : `.8rem`)};
  margin-bottom: ${(props) => (!props.hidedesc ? "0" : `.5rem`)};
  @media (min-width: 768px) {
    margin-top: ${(props) => (props.hidedesc ? "0" : `2rem`)};
  }
`;

export const CryptoBuyingTips = styled.div`
  display: none;
  text-indent: -9999px;
`;

export const StyledImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 42.86%;
  background-color: ${(props) => props.theme.grey_4};
`;

export const BlurredImg = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkqJn0HwADoQIPYDWurQAAAABJRU5ErkJggg==`;

export const StyledImageWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : `48px`)};
  height: ${(props) => (props.height ? props.height : `48px`)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.accent_3};
  margin-right: 0.5rem;
`;
