import styled from "styled-components";
import { Container, Row, Col } from "styled-bootstrap-grid";

const externalLinks = require("@/consts/externalLinks");

const StyledFooter = styled.footer`
  background: ${(props) => props.theme.accent_1};
  color: ${(props) => props.theme.white};
  padding: 1em;
  margin-top: 3em;
`;

const FooterInner = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.theme.pageWidth};
  padding: 0.5em;
`;

const StyledRightCol = styled(Col)`
  text-align: center;
  @media (min-width: 576px) {
    text-align: right;
  }
`;

const StyledCopyright = styled(Col)`
  padding-bottom: 1rem;
  text-align: center;
  @media (min-width: 576px) {
    text-align: left;
  }
`;

const StyledIcon = styled.i`
  font-size: 14px;
  color: white;
  margin-right: 2px;
`;

const Icon = ({ name, href }) => {
  return (
    <a href={href} rel={`dofollow`} target={`_blank`}>
      <StyledIcon className={`icon-${name}`} />
    </a>
  );
};

const socialList = [
  {
    name: `twitter`,
    url: `twitter`,
  },
  {
    name: `facebook`,
    url: `facebook`,
  },
  {
    name: `telegram`,
    url: `telegram`,
  },
  {
    name: `link`,
    url: `bitclout`,
  },
  {
    name: `link-1`,
    url: `linktree`,
  },
  {
    name: `tumblr`,
    url: `tumblr`,
  },
  {
    name: `blogger`,
    url: `blogger`,
  },
  {
    name: `link-2`,
    url: `gab`,
  },
  {
    name: `rss-alt`,
    url: `feedburner`,
  },
  {
    name: `instapaper`,
    url: `instapaper`,
  },
];

const Footer = () => {
  const socials = externalLinks.filter((link) => link.type === "social");
  const getSocial = (name) =>
    socials.find((social) => social.name.toLowerCase() === name.toLowerCase());
  return (
    <StyledFooter>
      <FooterInner>
        <Container fluid>
          <Row>
            <StyledCopyright col={6} xs={12}>
              © {new Date().getFullYear()} Crypto Buying Tips
            </StyledCopyright>
            <StyledRightCol col={6} xs={12}>
              {socialList.map((item) => (
                <Icon
                  key={item.name}
                  name={item.name}
                  href={getSocial(item.url).url}
                />
              ))}
            </StyledRightCol>
          </Row>
        </Container>
      </FooterInner>
    </StyledFooter>
  );
};

export default Footer;
