import styled from "styled-components";
import { Container, Row, Col } from "styled-bootstrap-grid";
import Sidebar from "@/components/Sidebar";
import theme from "@/styles/theme";

const StyledSection = styled.section`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.theme.pageWidth};
  padding: 0.4em;
  min-height: 80vh;
  @media (min-width: 768px) {
    padding: 1em;
  }
`;

const ContentSection = styled(Col)`
  img {
    max-width: 100%;
  }

  table {
    @media (max-width: 768px) {
      max-width: 100%;
      display: block;
      overflow-x: auto;
      font-size: 1rem;
    }
    text-align: left;
    font-size: 0.9rem;
  }
  table,
  th,
  td {
    border: 1px solid ${theme.grey_2_5};
    border-collapse: collapse;
  }

  th,
  td {
    padding: 0.3rem;
  }
`;

const getColWidth = (noSidebar) => (noSidebar ? 12 : 9);

const SectionWrapper = ({ children, noSidebar }) => {
  return (
    <StyledSection>
      <Container fluid>
        <Row>
          <ContentSection md={getColWidth(noSidebar)}>
            {children}
          </ContentSection>
          {!noSidebar && (
            <Col md={3}>
              <Sidebar />
            </Col>
          )}
        </Row>
      </Container>
    </StyledSection>
  );
};

export default SectionWrapper;
