const externalLinks = [
  {
    name: `Twitter`,
    url: `https://twitter.com/crypto_buy_tips`,
    type: `social`,
  },
  {
    name: `Facebook`,
    url: `https://www.facebook.com/cryptobuyingtips/`,
    type: `social`,
  },
  {
    name: `Telegram`,
    url: `https://t.me/s/cryptobuyingtips`,
    type: `social`,
  },
  {
    name: `Linktree`,
    url: `https://linktr.ee/cryptobuyingtips`,
    type: `social`,
  },
  {
    name: `InstaPaper`,
    url: `https://www.instapaper.com/p/cryptobuytips`,
    type: `social`,
  },
  {
    name: `FeedBurner`,
    url: `https://feeds.feedburner.com/CryptoBuyingTips`,
    type: `social`,
  },
  {
    name: `Tumblr`,
    url: `https://cryptobuyingtips.tumblr.com/`,
    type: `social`,
  },
  {
    name: `Blogger`,
    url: `https://cryptobuyingtips.blogspot.com/`,
    type: `social`,
  },
  {
    name: `BitClout`,
    url: `https://bitclout.com/u/cryptobuyingtips`,
    type: `social`,
  },
  {
    name: `Gab`,
    url: `https://gab.com/cryptobuyingtips`,
    type: `social`,
  },
  {
    name: `Coinbase`,
    url: `https://coinbase.com/join/learnt_q`,
    type: `exchange`,
    bg: `#0667d0`,
    twitter: `coinbase`,
  },
  {
    name: `UpHold`,
    url: `https://uphold.sjv.io/c/2882034/1084201/13619`,
    type: `exchange`,
    bg: `#49cc68`,
  },
  {
    name: `Binance`,
    url: `https://www.binance.com/en/register?ref=HY2SYXD0`,
    type: `exchange`,
    bg: `#F0B90B`,
    twitter: `binance`,
    us: false,
  },
  {
    name: `KuCoin`,
    url: `https://www.kucoin.com/?rcode=7e1K45`,
    type: `exchange`,
    bg: `#24AE8F`,
    twitter: `kucoincom`,
    us: true,
  },
  {
    name: `CoinFalcon`,
    url: `https://go.coinfalcon.com/join?ref=CFJSAJYSEPXM`,
    type: `exchange`,
    bg: `#1AB6FF`,
  },
  {
    name: `Ledger`,
    url: `https://shop.ledger.com/pages/ledger-nano-x?r=c57b14651ace`,
    type: `hardware`,
    bg: `#142533`,
    twitter: `Ledger`,
  },
  {
    name: `Ledger-Nano-S`,
    url: `https://shop.ledger.com/products/ledger-nano-s?r=c57b14651ace`,
    type: `hardware`,
    bg: `#142533`,
  },
  {
    name: `Ledger-Nano-X`,
    url: `https://shop.ledger.com/pages/ledger-nano-x?r=c57b14651ace`,
    type: `hardware`,
    bg: `#142533`,
  },
  {
    name: `TrezorHome`,
    url: `https://trezor.io/?offer_id=12&aff_id=3944`,
    bg: `#000000`,
    type: `hardwareSub`,
  },
  {
    name: `Trezor`,
    url: `https://shop.trezor.io/?offer_id=10&aff_id=3944`,
    bg: `#000000`,
    type: `hardware`,
    twitter: `trezor`,
  },
  {
    name: `Trezor-One-White`,
    url: `https://shop.trezor.io/product/trezor-one-white?offer_id=14&aff_id=3944`,
    type: `hardwareSub`,
  },
  {
    name: `Trezor-Model-T`,
    url: `https://shop.trezor.io/product/trezor-model-t?offer_id=15&aff_id=3944`,
    type: `hardwareSub`,
  },
  {
    name: `Trezor-CryptoSteel`,
    url: `https://shop.trezor.io/product/cryptosteel?offer_id=23&aff_id=3944`,
    type: `hardwareSub`,
  },
  {
    name: `Trezor-One-Metallic`,
    url: `https://shop.trezor.io/product/trezor-one-metallic?offer_id=24&aff_id=3944`,
    type: `hardwareSub`,
  },
  {
    name: `Trezor-One-Black`,
    url: `https://shop.trezor.io/product/trezor-one-black?offer_id=35&aff_id=3944`,
    type: `hardwareSub`,
  },
  {
    name: `Brave-Browser`,
    url: `https://brave.com/rhz292`,
    type: `browser`,
    twitter: `brave`,
  },
  {
    name: `LocalBitcoins`,
    url: `https://localbitcoins.com/?ch=19fq0`,
    type: `exchangeAffiliate`,
  },
  {
    name: `Changelly`,
    url: `https://changelly.com/?ref_id=u317t1gn0n464ez1`,
    type: `exchangeAffiliate`,
    bg: "#10d078",
  },
  {
    name: `Coinmama`,
    url: `https://go.coinmama.com/visit/?bta=60754&brand=coinmama`,
    type: `exchange`,
    bg: "#1F2374",
  },
  {
    name: `HotBit`,
    url: `https://www.hotbit.io/register?ref=637203`,
    type: `exchange`,
    bg: "#1bcdb4",
    twitter: `Hotbit_news`,
    us: true,
  },
  {
    name: `Mercatox`,
    url: `https://mercatox.com/?referrer=638023`,
    type: `exchange`,
    bg: "#196301",
    us: true,
  },
  {
    name: `GateIO`,
    url: `https://www.gate.io/signup/2887712`,
    type: `exchange`,
    bg: "#004450",
    twitter: `gate_io`,
    us: true,
  },
  {
    name: `Bittrex`,
    url: `https://bittrex.com/Account/Register?referralCode=3QQ-VKP-VI0`,
    type: `exchange`,
    bg: "#3784CE",
    twitter: `BittrexExchange`,
    us: true,
  },
  {
    name: `BitForex`,
    url: `https://www.bitforex.com/en/register?inviterId=2448827`,
    type: `exchange`,
    bg: "#14c393",
    twitter: `bitforexcom`,
    us: true,
  },
  {
    name: `Bitfinex`,
    url: `https://www.bitfinex.com/?refcode=yg6jZ4X79`,
    type: `exchange`,
    bg: "#8dd867",
    twitter: `bitfinex`,
    us: false,
  },
  {
    name: `Liquid`,
    url: `https://www.liquid.com/sign-up/?affiliate=cBHDBhpy75954`,
    type: `exchange`,
    bg: "#020c3b",
    twitter: `liquid_global`,
    us: true,
  },
  {
    name: `Tokok`,
    url: `https://www.tokok.com/reg?code=WJSVOLJQ`,
    type: `exchange`,
    bg: "#ef7843",
    us: true,
  },
  {
    name: `YoBit`,
    url: `https://yobit.net/?bonus=hinJj`,
    type: `exchange`,
    bg: "#465a73",
    twitter: `YobitExchange`,
    us: true,
  },
  {
    name: `P2PB2B`,
    url: `https://p2pb2b.io/referral/344059c1-9799-4439-b3dc-13bdcdea4550`,
    type: `exchange`,
    bg: "#4aa657",
    twitter: `p2pb2b`,
    us: true,
  },
  {
    name: `OKEx`,
    url: `https://www.okex.com/join/8669887`,
    type: `exchange`,
    bg: "#2d60e0",
    twitter: `OKEx`,
    us: false,
  },
  {
    name: `HuobiGlobal`,
    url: `https://www.huobi.com/en-us/topic/invited/?invite_code=7apk5`,
    type: `exchange`,
    bg: "#1b2945",
    twitter: `HuobiGlobal`,
    us: false,
  },
  {
    name: `ProbitExchange`,
    url: `https://www.probit.com/r/84970260`,
    type: `exchange`,
    bg: "#4231c8",
    twitter: `ProBit_Exchange`,
    us: true,
  },
  {
    name: `Poloniex`,
    url: `https://poloniex.com/signup?c=9QBBNYCZ`,
    type: `exchange`,
    bg: "#068485",
    twitter: `Poloniex`,
    us: true,
  },
  {
    name: `MXC`,
    url: `https://www.mxc.ai/auth/signup?inviteCode=14Nja`,
    type: `exchange`,
    bg: "#41b37d",
    twitter: `MXC_Exchange`,
    us: true,
  },
  {
    name: `OceanEx`,
    url: `https://oceanex.pro/signup?referral=FUY8AV`,
    type: `exchange`,
    bg: "#20A3FF",
    twitter: `OceanexOfficial`,
    us: true,
  },
  {
    name: `BitMart`,
    url: `https://www.bitmart.com/en?r=7X6ssN`,
    type: `exchange`,
    bg: "#0C9C98",
    twitter: `BitMartExchange`,
    us: false,
  },
  {
    name: `BitMax`,
    url: `https://bitmax.io/#/register?inviteCode=TXQPUYUT`,
    type: `exchange`,
    bg: "#007aff",
    twitter: `BitMax_Official`,
    us: false,
  },
  {
    name: `Bilaxy`,
    url: `https://bilaxy.com/user/register?intro=1443897`,
    type: `exchange`,
    bg: "#05aad7",
    us: false,
  },
  {
    name: `Paxful`,
    url: `https://paxful.com/?r=Z3Q3XDBa8db`,
    type: `exchange`,
    bg: "#00a5ef",
    twitter: `paxful`,
  },
  {
    name: `CryptoHopper`,
    url: `https://www.cryptohopper.com/?atid=14124`,
    type: `bot`,
    twitter: `cryptohopper`,
  },
  {
    name: `Bunq`,
    url: `https://www.getbunq.app/26H4TQR/225JFQ/?uid=3`,
    type: `bank`,
    twitter: `bunq`,
  },
  {
    name: `N26`,
    url: `https://n26.com/`,
    type: `bank`,
    twitter: `n26`,
  },
  {
    name: `Revolut`,
    url: `https://revolut.com/`,
    type: `bank`,
    twitter: `RevolutApp`,
  },
  {
    name: `Crypto.com`,
    url: `https://platinum.crypto.com/r/4jscksotqt`,
    type: `exchange`,
    twitter: `cryptocom`,
  },
  {
    name: `Luno`,
    url: `https://www.luno.com/invite/YWB5KD`,
    type: `wallet-app`,
  },
  {
    name: `NordVPN`,
    url: `https://go.nordvpn.net/aff_c?offer_id=15&aff_id=45404`,
    type: `vpn`,
  },
  {
    name: `AtlasVpn`,
    url: `http://atlasvpn.go2cloud.org/aff_c?offer_id=39&aff_id=533`,
    type: `vpn`,
  },
  {
    name: `SurfShark`,
    url: `https://get.surfshark.net/aff_c?offer_id=6&aff_id=6966`,
    type: `vpn`,
  },
  {
    name: `CryptoTrader.Tax`,
    url: `http://cryptotrader.tax?fpr=do882`,
    type: `others`,
  },
  {
    name: `CryptoUltimatum`,
    url: `https://c8af2x5wxdwesje8ywyd08hxes.hop.clickbank.net/`,
    type: `others`,
  },
  {
    name: `BitMex`,
    url: `https://www.bitmex.com/register/4s0b1N`,
    type: `exchangeDerivatives`,
  },
  {
    name: `1Inch`,
    url: `https://app.1inch.io/#/r/0x10aFCe2361DbB4c91651cBBF44404d587ec1b1cf`,
    type: `exchangeDefi`,
  },
  {
    name: `1inch-exchange`,
    url: `https://app.1inch.io/#/r/0x10aFCe2361DbB4c91651cBBF44404d587ec1b1cf`,
    type: `exchangeDefi`,
    bg: "#2f8af5",
  },
  {
    name: `pancakeswap-v2`,
    url: `https://exchange.pancakeswap.finance/#/swap`,
    type: `exchangeDefi`,
    bg: "#1fc7d4",
  },
  {
    name: `pancakeswap`,
    url: `https://exchange.pancakeswap.finance/#/swap`,
    type: `exchangeDefi`,
    bg: "#1fc7d4",
  },
  {
    name: `metamask`,
    url: `https://metamask.io/`,
    type: `wallet-app`,
    bg: "#037dd6",
  },
  {
    name: `cryptosteel-capsule-solo`,
    url: `https://shop.ledger.com/products/cryptosteel-capsule-solo?r=c57b14651ace`,
    type: `hardware-others`,
    bg: `#142533`,
  },
];

module.exports = externalLinks;
